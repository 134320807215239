@tailwind base;
@tailwind components;
@tailwind utilities;

svg { vertical-align: baseline; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.variable {
    border-radius: 5px;
    border: 1px solid;
    padding: 3px;
    background: #336699;
    color: white;
}


.tooltip {
    z-index: 1000;
    position: absolute;
    background: #336699;
    width: 300px;
    min-height: 200px;
    color: white;
    display: none;
    padding: 10px;
    border-radius:5px;
}

.editableInput {
    background: white;
    color: black;
    padding:5px;
    width:100%;
}

.draggableVar {
    display: inline-block;
    padding: 3px;
    background: yellow;
    cursor: move !important;
}

.switchInput {
    padding: 3px;
}