@import '~antd/dist/antd.less';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;1,300&display=swap');
svg:not(:root) { overflow: visible; }
@primary-color: #1DA57A;
@error-color: #F76C6C;
@border-radius-base: 5px;
@layout-sider-background: #182628;
@layout-trigger-background: #14191a;
@menu-bg: #182628;
@menu-dark-bg: #182628;
@menu-dark-submenu-bg: #14191a;
@menu-dark-arrow-color: @primary-color;
@table-header-bg: @primary-color;
@table-header-color: #fff;
@card-head-color: #fff;
@card-head-background: @primary-color;
@drawer-bg: #f0f2f5;

:root {
  --primary-color: @primary-color;
}

.lightMenu{
  background: #f0f2f5;
}

.SpecialSteps > .ant-steps-item-container  > .ant-steps-item-tail::after {
  background: transparent !important;
}

#SpecialSubMenu.ant-menu-item-selected {
  background-color: white !important;
  color: black !important;
}
#SpecialSubMenu.ant-menu-item-selected:hover {
  color: #1DA57A !important;
}

.cardHearderButton{
  background: #f0f2f5;
  border: 0px;
}

.selectNoRadius{
  width: 100%;
  border-radius: 0px;
}
.App {
  height:100%;
  background:#fff;
  font-family: 'Montserrat', sans-serif;;
}

.login-form {
  width:100%;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}

.sider_text{
  font-size: 14px;
}

.layout {
  background:#fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100vh;
  width: 100%;
}

.layout-unauthed {
    height:100%;
    background:#fff;
  
}

.footer {
    flex-shrink: 0;
    height: 30px;
    padding-top: 10px;
}

.header {
  background-color: #f0f2f5;
  height: 2.5em;
}

.graph_card1 {
  background-color: white;
  margin-top: 1em;
  border-radius: 7px;
  width:39%;
}

.graph_card2 {
  background-color: white;
  margin-top: 1em;
  border-radius: 7px;
  width:60%;
}

.defaultLink {
    color: black;
}

@media (max-width: 862px) {
  .graph_card1 {
    width:50%;
  }
  
  .graph_card2 {
    width:49%;
  }
}

.graph_card_divider {
  width:1%;
}

.graph {
  margin: -1em;
  height: 15em;
  width:100%;
}

.mycontent {
  margin: 10px 16px;
  flex-shrink: 0;
  flex-grow: 1;
}

.contentfooter{
  background-color: #f0f2f5;
  overflow: auto;
}

.current_account_project{
  text-align: center;
}

.current_account{
  margin-top: -55px;
  color: white;
}

.current_project{
  margin-top: 15px;
  color: white;
  width: 90%;
}

.menus_parent{
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.flexContainer {
  display: flex;
  height: 80vh;
  width: 100%;
  flex-direction: column;
}

.flexHeader {
  height: 10%;
}

.flexContent {
  flex: 1;
  overflow: auto;
  height: 85%;
}

.flexFooter {
  margin-top: 15px;
  height: 5%;
}

@media (max-height: 580px) {
  .flexContainer {
    display: flex;
    height: 78vh;
    width: 100%;
    flex-direction: column;
  }
}

@media (max-height: 540px) {
  .flexContainer {
    display: flex;
    height: 74vh;
    width: 100%;
    flex-direction: column;
  }
}

@media (max-height: 460px) {
  .flexContainer {
    display: flex;
    height: 70vh;
    width: 100%;
    flex-direction: column;
  }
}

@media (max-height: 410px) {
  .flexContainer {
    display: flex;
    height: 60vh;
    width: 100%;
    flex-direction: column;
  }

  .flexHeader {
    height: 20%;
  }
  
  .flexContent {
    flex: 1;
    overflow: auto;
    height: 79%;
  }
  
  .flexFooter {
    margin-top: 15px;
    height: 1%;
  }
}

@media (max-height: 340px) {
  .flexContainer {
    display: flex;
    height: 50vh;
    width: 100%;
    flex-direction: column;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hoverPointer:hover {
  cursor: pointer;
}

.largeList {
    height: 60px;
}

.lineTrough{
  text-decoration: line-through;
}

.activeSource {
  border: 2px solid #F76C6C;
}

.inActiveSource{
  border: 2px solid white;
}

.nodes-creation-drawer-layout{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 90vh;
}

.nodes-creation-drawer-steps{
  margin-top: -20px;
  margin-bottom: 25px;
  height: 5vh;
}

.nodes-creation-drawer-steps2{
  margin-top: -20px;
  margin-bottom: 45px;
  height: 5vh;
}

.nodes-creation-drawer-content{
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
}

.unvisible{
  display: none;
}

.spin{
  z-index: 1000000;
}

.nodes-creation-layout{
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 90vh;
}

.nodes-steps{
  padding-left: 5vw;
  padding-right: 5vw;
  height: 10vh;
}

.nodes-creation-content{
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
}

.nodes-actions{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -15px;
  margin-top: 5px;
}

.connection-creation-content-and-actions{
  height: 100%;
}

.connection-creation-content{
  overflow-y: scroll;
  overflow-x: hidden;
  height: 95%;
}

.connection-actions{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}

.steps-content {
  margin-bottom:20px;
}

.activeAccount {
  background: #F76C6C;
  color: white;
}

.activationCode {
  word-break: break-all;
}

.addaccount{
  margin-top:20px;
  border: none;
  background-color:green;
}

.addaccount:hover{
  background-color:#53BD80;
}

.addaccount:focus{
  background-color:#53BD80;
}

.headerChain p{
    color: #aaa;
    font-variant: small-caps;
}
.headerChain {
    font-weight: bold;
    font-size: 11px;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

thead[class*="ant-table-thead"] th{
  font-weight: bolder;
}

.searchInput{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 15px;
}

.ant-table-thead > tr > th {
  background: @primary-color !important;
}

.ant-table-filter-trigger.active {
  color: #F76C6C;
}

.roundModal {
  border-radius: 5px;
}
