.react-flow__node-inputNode {
    font-size: 12px;
    background: #fff;
    border: 1px solid #0041d0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 250px;
}

.headerNode {
    text-align:center;
    width: 100%;
    background: #ddd;
    border-top-right-radius: 5px;
    font-weight:bold;
    padding: 5px 5px;
}

.react-flow__node-functionNode {
    width: 200px;
    font-size: 12px;
    background: #fff;
    border: 1px solid #000;
}

.react-flow__node-outputNode {
    font-size: 12px;
    background: #fff;
    border: 1px solid #ff0072;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    width: 250px;
}

.elInNode {
    width: 100%;
    padding-left: 15px;
}

.reactflow-wrapper {
    flex-grow: 1;
    height: 100%;
}

.functionNodeElements {
    padding: 5px;
}

.customHandle {
    width: 8px;
    height: 8px;
}

